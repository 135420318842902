<template>
	<div class="relenish-information">
		<div class="title">补充证件照资料</div>
		
		<div class="tip" v-if="ApplyState!==2">
			<div v-if="ApplyState===4" style="padding-bottom: 20px;color: #F56C6C;font-size: 15px;">审核失败，请尽快处理。{{CheckFailReason}}</div>
			<div>图片上传要求:</div>
			<div>1.图片格式要求：JPG/PNG/JPEG/BMP 等类型，<span style="color: #F56C6C;">大小在 1M 以内</span>，文件名中不能使用字符/、、:、*、?、”、<、>
			</div>
			<div>2.请上传<span style="color: #F56C6C;">原件图片，或复印件加盖企业有色公章</span></div>
			<div>3.商户照片可带水印，但是不得出现其他公司水印信息</div>
		</div>
		<div class="tip" v-else>
			<div style="color: @409EFF;font-weight: bold;">等待Adapay官方审核...</div>
			<div>关于审核时间：工作日17：30之前提交，当天审核</div>
			<div>关于审核结果：审核结果将会通过短信发送至联系人手机号，若未审核通过，请及时处理</div>
		</div>
		

		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px">

			<!-- 营业执照 -->
			<el-form-item label="营业执照:" prop="LicenseImgUrl">
				<div class="item-tip">
					原件图片或加盖企业有色公章的复印件，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnPNCw03Y3mtHZh9r3w7Lv18#akovrA" target="_blank">查看示例</el-link>
				</div>
				<div class="img-box">
					<el-upload :action="imgApi" class="avatar-uploader"
						:on-success="(res, file)=>{handleSuccess(res, file, 'LicenseImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<img v-if="ruleForm.LicenseImgUrl" :src="imgUrl + ruleForm.LicenseImgUrl"
							@click.stop="handlePictureCardPreview('LicenseImgUrl')" class="avatar"></img>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload v-if="ruleForm.LicenseImgUrl && !isLook" class="change-image" :action="imgApi"
						:on-success="(res, file)=>{handleSuccess(res, file, 'LicenseImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<el-link type="primary" style="margin-bottom: 10px;">更换图片</el-link>
					</el-upload>
				</div>
			</el-form-item>


			<!-- 法人手持身份证正面 -->
			<el-form-item label="法人手持身份证正面:" prop="CorporationCardIdFrontImgUrl">
				<div class="item-tip">
					真实拍摄原件或原件彩色扫描件，不能使用复印件，请确保法人本人手持，面部和身份证均在照片内，并且能看清文字和身份证号，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnPNCw03Y3mtHZh9r3w7Lv18#MCPnvX" target="_blank">查看示例</el-link>
				</div>
				<div class="img-box">
					<el-upload :action="imgApi" class="avatar-uploader"
						:on-success="(res, file)=>{handleSuccess(res, file, 'CorporationCardIdFrontImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<img v-if="ruleForm.CorporationCardIdFrontImgUrl"
							:src="imgUrl + ruleForm.CorporationCardIdFrontImgUrl"
							@click.stop="handlePictureCardPreview('CorporationCardIdFrontImgUrl')" class="avatar"></img>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload v-if="ruleForm.CorporationCardIdFrontImgUrl && !isLook" class="change-image" :action="imgApi"
						:on-success="(res, file)=>{handleSuccess(res, file, 'CorporationCardIdFrontImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<el-link type="primary" style="margin-bottom: 10px;">更换图片</el-link>
					</el-upload>
				</div>
			</el-form-item>


			<!-- 法人身份证反面 -->
			<el-form-item label="法人身份证反面:" prop="CorporationCardIdBackImgUrl">
				<div class="item-tip">
					真实拍摄原件或原件彩色扫描件，不能使用复印件，请确保能够看清文字，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnPNCw03Y3mtHZh9r3w7Lv18#nIFnXA" target="_blank">查看示例</el-link>
				</div>
				<div class="img-box">
					<el-upload :action="imgApi" class="avatar-uploader"
						:on-success="(res, file)=>{handleSuccess(res, file, 'CorporationCardIdBackImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<img v-if="ruleForm.CorporationCardIdBackImgUrl"
							:src="imgUrl + ruleForm.CorporationCardIdBackImgUrl"
							@click.stop="handlePictureCardPreview('CorporationCardIdBackImgUrl')" class="avatar"></img>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload v-if="ruleForm.CorporationCardIdBackImgUrl && !isLook" class="change-image" :action="imgApi"
						:on-success="(res, file)=>{handleSuccess(res, file, 'CorporationCardIdBackImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<el-link type="primary" style="margin-bottom: 10px;">更换图片</el-link>
					</el-upload>
				</div>
			</el-form-item>


			<!-- 开户许可证 -->
			<el-form-item label="开户许可证:" prop="AccountOpeningPermitImgUrl">
				<div class="item-tip">
					对公开户许可证、对公银行结算账号信息、或电子打款凭证或者带有详细银行账号回执等均可，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnPNCw03Y3mtHZh9r3w7Lv18#e67OcF" target="_blank">查看示例</el-link>
				</div>
				<div class="img-box">
					<el-upload :action="imgApi" class="avatar-uploader"
						:on-success="(res, file)=>{handleSuccess(res, file, 'AccountOpeningPermitImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<img v-if="ruleForm.AccountOpeningPermitImgUrl"
							:src="imgUrl + ruleForm.AccountOpeningPermitImgUrl "
							@click.stop="handlePictureCardPreview('AccountOpeningPermitImgUrl')" class="avatar"></img>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload v-if="ruleForm.AccountOpeningPermitImgUrl && !isLook" class="change-image" :action="imgApi"
						:on-success="(res, file)=>{handleSuccess(res, file, 'AccountOpeningPermitImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<el-link type="primary" style="margin-bottom: 10px;">更换图片</el-link>
					</el-upload>
				</div>
			</el-form-item>


			<!-- 门头照 -->
			<el-form-item label="门头照:" prop="StoreImgUrl">
				<div class="item-tip">
					若您有线下门店，可上传门头照；若没有，可上传公司门头照。门店名信息或公司名清晰可见，不可缺字或者局部拍摄，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnPNCw03Y3mtHZh9r3w7Lv18#0HLXtT" target="_blank">查看示例</el-link>
				</div>
				<div class="img-box">
					<el-upload :action="imgApi" class="avatar-uploader"
						:on-success="(res, file)=>{handleSuccess(res, file, 'StoreImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<img v-if="ruleForm.StoreImgUrl" :src="imgUrl + ruleForm.StoreImgUrl "
							@click.stop="handlePictureCardPreview('StoreImgUrl')" class="avatar"></img>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload v-if="ruleForm.StoreImgUrl && !isLook" class="change-image" :action="imgApi"
						:on-success="(res, file)=>{handleSuccess(res, file, 'StoreImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<el-link type="primary" style="margin-bottom: 10px;">更换图片</el-link>
					</el-upload>
				</div>
			</el-form-item>


			<!-- 室内照 -->
			<el-form-item label="室内照:" prop="StoreIndoorImgUrl">
				<div class="item-tip">
					若您有线下门店，可上传门店室内照；若没有，可上传公司内部环境照。室内照片与门头照的环境连贯，展现商户经营场景，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnPNCw03Y3mtHZh9r3w7Lv18#BJ46QY" target="_blank">查看示例</el-link>
				</div>
				<div class="img-box">
					<el-upload :action="imgApi" class="avatar-uploader"
						:on-success="(res, file)=>{handleSuccess(res, file, 'StoreIndoorImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<img v-if="ruleForm.StoreIndoorImgUrl" :src="imgUrl + ruleForm.StoreIndoorImgUrl "
							@click.stop="handlePictureCardPreview('StoreIndoorImgUrl')" class="avatar"></img>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<el-upload v-if="ruleForm.StoreIndoorImgUrl && !isLook" class="change-image" :action="imgApi"
						:on-success="(res, file)=>{handleSuccess(res, file, 'StoreIndoorImgUrl')}"
						:before-upload="beforeUpload" :show-file-list="false">
						<el-link type="primary" style="margin-bottom: 10px;">更换图片</el-link>
					</el-upload>
				</div>
			</el-form-item>

		</el-form>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>


		<div class="submit">
			<el-button style="margin-right: 30px;" @click="handleBack">返回</el-button>
			<el-button type="primary" :loading="loading" @click="handleSubmit" v-if="!isLook">提交审核</el-button>
		</div>

		<el-dialog :visible.sync="successVisible" title="提示" width="500px">
			<div style="line-height: 26px;">补充资料提交成功，在工作日上午9点-下午5点半进行审核，审核结果将会通过短信发送至联系人手机号，若未审核通过，请及时处理</div>
			<div slot="footer" style="text-align: center">
				<el-button type="primary" @click="handleBack">好的</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync="failVisible" title="提示" width="500px">
			<div style="line-height: 26px;">进件成功后，超过5个工作日未审核通过证照材料，本次申请提交失败，入驻申请关闭。您需要更换手机号重新进件</div>
			<div slot="footer" style="text-align: center">
				<el-button type="primary" @click="handleToSubmitPage">重新进件</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		adapayMerchantappendPhoto,
		adapayMerchantInfo
	} from '@/api/TurnTomySelf.js';

	import config from '@/config/index';

	export default {
		components: {},
		data() {
			return {
				dialogVisible: false,
				dialogImageUrl: '',
				imgApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,

				successVisible: false,
				failVisible: false,

				loading: false,
				
				isLook: false,
				ApplyState: 0,
				CheckFailReason: '',

				ruleForm: {
					LicenseImgUrl: '',
					CorporationCardIdFrontImgUrl: '',
					CorporationCardIdBackImgUrl: '',
					AccountOpeningPermitImgUrl: '',
					StoreImgUrl: '',
					StoreIndoorImgUrl: ''
				},
				rules: {
					LicenseImgUrl: [{
						required: true,
						trigger: 'change',
						message: '请上传营业执照'
					}],
					CorporationCardIdFrontImgUrl: [{
						required: true,
						trigger: 'change',
						message: '请上传法人手持身份证正面照'
					}],
					CorporationCardIdBackImgUrl: [{
						required: true,
						trigger: 'change',
						message: '请上传法人身份证反面照'
					}],
					AccountOpeningPermitImgUrl: [{
						required: true,
						trigger: 'change',
						message: '请上传开户许可证'
					}],
					StoreImgUrl: [{
						required: true,
						trigger: 'change',
						message: '请上传门店照'
					}],
					StoreIndoorImgUrl: [{
						required: true,
						trigger: 'change',
						message: '请上传室内照'
					}],
				}
			}
		},
		created() {
			this.getData();
			this.isLook = this.$route.query.isLook ? true:false;
		},
		methods: {
			async getData(){
				const res = await adapayMerchantInfo();
				
				if (res.IsSuccess){
					for (let item in this.ruleForm){
						this.ruleForm[item] = res.Result[item];
					}
					this.ApplyState = res.Result.ApplyState || 0;
					this.CheckFailReason = res.Result.CheckFailReason || '';
				}
			},
			handleRemove(key) {
				this.ruleForm[key] = '';
			},
			handlePictureCardPreview(key) {
				this.dialogImageUrl = this.imgUrl + this.ruleForm[key];
				this.dialogVisible = true;
			},
			handleSuccess(res, file, key) {
				this.ruleForm[key] = res[0];
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!isLt1M) {
					this.$message.error('图片大小请控制在1M以内');
				}
				return isLt1M;
			},

			handleSubmit() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.save()
					} else {}
				});
			},
			async save() {
				this.loading = true;

				const res = await adapayMerchantappendPhoto(this.ruleForm);
				this.loading = false;

				if (res.IsSuccess) {
					this.successVisible = true;
				}else{
					this.failVisible = false;
				}
			},


			handleBack() {
				this.$router.replace({
					name: 'settingPay'
				})
			},
			handleToSubmitPage() {
				this.$router.replace({
					name: 'submitInformation'
				})
			}
		}
	}
</script>

<style lang="less">
	.relenish-information {


		background: #fff;
		font-size: 14px;
		padding-bottom: 150px;

		display: flex;
		flex-direction: column;

		.title {
			flex: 0 0 auto;
			height: 40px;
			line-height: 40px;
			padding-left: 20px;
			background-color: #f8f8f9;
			font-size: 16px;
		}

		.tip {
			color: #666;
			line-height: 26px;
			padding: 20px 30px;
		}

		.avatar-uploader .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
		}

		.avatar-uploader .el-upload:hover {
			border-color: #409EFF;
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 148px;
			height: 148px;
			line-height: 148px;
			text-align: center;
		}

		.avatar {
			width: 148px;
			height: 148px;
			display: block;
			border-radius: 6px;
		}

		.img-box {
			display: flex;
			align-items: baseline;
		}

		.change-image {
			height: 20px;
			line-height: 20px;
			margin-left: 10px;
		}

		.item-tip {
			color: #999;
			display: flex;
			align-items: center;
		}


		.submit {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 60px;
			background-color: #fff;
			border-top: 1px solid #ddd;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>
